import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { analytics, logEvent } from "../firebase";

import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";
import logoWhite from "../assets/logo-white.png";
import lucasImage from "../assets/lucas.png";

const glassPaper = {
  background: "rgba(255, 255, 255, 0.07)",
  backdropFilter: "blur(20px)",
  borderRadius: "20px",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
  },
};

function LandingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleConsultationClick = () => {
    logEvent(analytics, "schedule_consultation_click");
  };

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
        minHeight: "100vh",
        backgroundImage:
          "linear-gradient(135deg, rgba(0,0,0,0.9), rgba(0,0,0,0.7))",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ py: { xs: 6, md: 12 } }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <img
                    src={logoWhite}
                    alt="Logo"
                    style={{ width: "100%", maxWidth: "300px", height: "auto" }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ mb: 2, fontSize: { xs: "1.5rem", md: "2rem" } }}
                  >
                    Lucas Frischmann
                  </Typography>
                  <Typography
                    variant="h5"
                    paragraph
                    sx={{
                      mb: 2,
                      color: "text.secondary",
                      fontSize: { xs: "1.25rem", md: "1.5rem" },
                    }}
                  >
                    Product & Engineering Leader | Investor
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" sx={{ mb: 4 }}>
                With a proven track record at industry giants like X (Twitter),
                Snap (Snapchat), and Meta (Facebook), as well as numerous
                startups, I excel in driving product innovation and engineering
                excellence. As an investor, I seek opportunities to provide
                smart capital, leveraging my expertise to foster growth and
                success beyond financial investment.
              </Typography>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={isMobile}
                  href="#contact"
                >
                  Accelerate Your Growth
                </Button>
              </Box> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: { xs: 250, md: 300 },
                }}
                alt="Lucas Frischmann"
                src={lucasImage}
                variant="square"
              />
            </Grid>
          </Grid>

          <Box sx={{ my: 12 }} id="expertise">
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 8 }}>
              How I Can Help
            </Typography>
            <Grid container spacing={4}>
              {[
                {
                  category: "Strategic Leadership",
                  shortDescription:
                    "I excel in strategic planning, leadership development, and organizational design, helping companies navigate complex environments and achieve sustainable growth.",
                },
                {
                  category: "Engineering & Product",
                  shortDescription:
                    "With a strong background in technical leadership, product development, and team scaling, I bring deep expertise in building and scaling high-performing engineering teams and products.",
                },
                {
                  category: "Investment",
                  shortDescription:
                    "As an investor, I seek opportunities to provide smart capital, leveraging my expertise to foster growth and success beyond financial investment.",
                },
              ].map((category, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Box sx={{ ...glassPaper, p: 3 }}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {category.category}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {category.shortDescription}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ my: 12 }} id="contact">
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 8 }}>
              Work with Me
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{ color: "text.secondary", maxWidth: 600, mx: "auto", mb: 4 }}
            >
              Let's discuss how my expertise can accelerate your organization's
              growth and success. As an investor, I am eager to partner with
              companies where I can contribute not just capital, but also my
              extensive experience.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                href="mailto:lucas@frischmann.io"
                onClick={handleConsultationClick}
              >
                Contact Me
              </Button>
            </Box>
          </Box>

          <Box sx={{ mt: 12, textAlign: "center" }}>
            <IconButton
              color="primary"
              href="https://www.linkedin.com/in/lucasfrischmann/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton color="primary" href="mailto:lucas@frischmann.io">
              <EmailIcon />
            </IconButton>
          </Box>

          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="body2" color="text.secondary">
              © {new Date().getFullYear()} Lucas Frischmann. All rights
              reserved. Contact:{" "}
              <a
                href="mailto:lucas@frischmann.io"
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                }}
              >
                lucas@frischmann.io
              </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default LandingPage;
